<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "ColdStorageIndex",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>
<style lang="scss" scoped></style>
