import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from "../layout/index.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Layout,
    redirect: "refrigeratory",
    children: [{
      path: "refrigeratory",
      name: "refrigeratory",
      component: () => import("../views/refrigeratory/index.vue"),

    }],
  }

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
